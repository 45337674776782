<template>
  <div class="onlineCourses">
    <div class="title">
      <span>课时管理 </span>
    </div>
    <el-card class="condition_style2">
      <div class="flex">
        <div class="add">
          <el-button type="primary" size="small" @click="add('true')">新增课程</el-button>
        </div>
        <div>
          <el-form :inline="true" class="flex" :model="searchForm">
            <!-- <el-form-item>
              <el-select v-model="searchForm.start" placeholder="请选择状态">
                <el-option v-for="(item, index) in startList" :key="index" :label="item.label" :value="item.value">
                </el- >
              </el-select>
            </el-form-item> -->
            <el-form-item>
              <el-input v-model="searchForm.itemName" placeholder="请输入课时名称" suffix-icon="el-icon-search" clearable>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
        </el-table-column>
        <el-table-column prop="itemName" label="课时名称" min-width="150" align="center"></el-table-column>
        <el-table-column prop="coverUrl" label="封面图" min-width="150" align="center">
          <template slot-scope="scope" v-if="scope.row.coverUrl">
            <img class="img_121" :src="scope.row.coverUrl">
          </template>
        </el-table-column>
        <el-table-column prop="itemTitle" label="课程简介" min-width="200" align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope" v-if="scope.row.itemTitle">
            <div class='myNote' v-html="scope.row.itemTitle"></div>
          </template>
        </el-table-column>
        <el-table-column prop="durationLabel" label="课时" min-width="100" align="center"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="100" align="center">
          <template slot-scope="scope">
            <span class="dot" :class="stateClass(scope.row.status)"></span> {{showState(scope.row.status)}}
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="最后操作时间" min-width="200" align="center"></el-table-column>
        <el-table-column prop="updateBy" label="最后操作人" min-width="100" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="130" align="center">
          <template slot-scope="scope">
            <div class="flex">
              <div class="text_center">
                <el-button type="text" size="small" class="right_btn" @click="changeState(scope.row,1)">
                  {{scope.row.status==1?"下架":"上架"}} </el-button>
                <el-button type="text" size="small" @click="add(scope.row.id)">编辑 </el-button>
                <el-button type="text" size="small" @click="changeState(scope.row,false)">删除 </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination :page="current" :size="size" :total="total" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"></pagination>
    </el-card>
  </div>
</template>


<script>
  import pagination from "@/components/pagination";
  import {
    courseTimeList,
    courseTimeStatus,
    deleteCourseTime,
  } from "@/api/api";
  export default {
    components: {
      pagination,
    },
    data() {
      return {
        tableHeight: this.$util.getHeight(350),
        size: 10, //每页条数
        current: 1, //当前条数
        total: 0,
        searchForm: {
          itemName: "",
        },
        tableData: [],
        startList: [{
          value: null,
          label: "全部",
        }, {
          value: "0",
          label: "上架",
        }, {
          value: "1",
          label: "下架",
        }, {
          value: "2",
          label: "待上架",
        }],
        showBtn: false,
      };
    },
    computed: {
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
    },
    created() {
      if (this.$route.params.form) {
        let form = JSON.parse(this.$route.params.form)
        this.current = form.current
        this.size = form.size
      }
      this.getList();
    },
    mounted() {
      window.addEventListener('resize', () => {
        let num = this.showBtn ? 300 : 350
        this.getTableHeight(num)
      })
    },
    methods: {
      getTableHeight(val) {
        this.tableHeight = this.$util.getHeight(val)
      },
      getList(val) {
        if (val) {
          this.current = 1;
        }
        this.getTableHeight(330)
        this.$request(courseTimeList(this.current, this.size), this.searchForm).then((res) => {
          if (res.code === 0) {
            let data = res.data;
            this.tableData = data.records;
            this.total = Number(data.total);
          }
        });
      },
      //   编辑排序
      editSort() {
        let ids = [];
        let order = [];
        this.tableData.find((item) => {
          ids.push(item.id);
          order.push(item.orderNum);
        });
        this.$request(batchOrderNews, {
          ids,
          order
        }).then((res) => {
          if (res.code === 0) {
            this.showBtn = false;
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      // 推荐、上架、删除
      changeState(val, bool) {
        let text;
        if (!bool) {
          text = "确定删除？";
        } else if (bool == 1) {
          text = val.status == 1 ? "确定下架？" : "确定上架？";
        }
        val.bool = bool;
        this.$util.isSwal(text, this.changeStateSubmit, val);
      },
      changeStateSubmit(val) {
        let url = !val.bool ? this.$request(deleteCourseTime, {
          id: val.id
        }) : this.$request(courseTimeStatus, {
          id: val.id,
          status: val.status == "0" ? "1" : "0"
        });
        url.then((res) => {
          if (res.code === 0) {
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      //   新增科普文章
      add(val) {
        this.$router.push({
          name: "coursesTimeAdd",
          params: {
            id: val,
            form: JSON.stringify({
              current: this.current,
              size: this.size
            }),
          },
        });
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },
      showState(val) {
        switch (val) {
          case 0:
            return "待上架";
          case 1:
            return "上架";
          case 2:
            return "下架";
        }
      },
      stateClass(val) {
        switch (val) {
          case 0:
            return "yellow_dot";
          case 1:
            return "green_dot";
          case 2:
            return "grey_dot";
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .onlineCourses {
    .add {
      padding-top: 3px;
      margin-right: 24px;
    }

    .right_btn {
      margin-left: 8px;
    }
  }
</style>